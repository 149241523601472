<template>
    <v-expansion-panels accordion>
        <v-expansion-panel v-for="item of documentCategories" :key="item.category">
            <v-expansion-panel-header>
                {{ item.category }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <DocumentAlert v-for="document of item.documents" :key="document.id" :document="document" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import DocumentAlert from './DocumentAlert.vue';

export default {
    name: 'DocumentsListByCategories',

    props: {
        documentCategories: { type: Array, required: true }
    },

    components: {
        DocumentAlert
    }
};
</script>