<template>
    <v-container :class="{ 'fill-height': missingProgram }">
        <template v-if="program !== null">
            <v-row>
                <v-col>
                    <v-card class="axessia-card no-title" outlined>
                        <v-toolbar class="mb-0 mx-2" dense flat :prominent="$vuetify.breakpoint.xs">
                            <h2 class="primary--text title">
                                {{ program.title }}

                                <v-tooltip v-if="program.reference" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip color="primary" class="mr-4" label x-small v-on="on">
                                            {{ program.reference }}
                                        </v-chip>
                                    </template>
                                    Référence programme
                                </v-tooltip>
                            </h2>

                            <v-spacer />

                            <CreateDenonciationDialog :initProgramId="program.id" redirectOnCreation>
                                <template v-slot:activator="{ on: dialog }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" small v-on="{ ...dialog, ...tooltip }">
                                                <v-icon small left> far fa-address-card </v-icon>
                                                Dénoncer
                                            </v-btn>
                                        </template>
                                        Dénoncer un nouveau contact
                                    </v-tooltip>
                                </template>
                            </CreateDenonciationDialog>

                            <v-item-group v-model="view" class="d-inline-block" mandatory>
                                <v-item :value="'map'" class="mx-4" v-slot="{ active, toggle }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="toggle" icon v-on="on">
                                                <v-icon :color="active ? 'primary' : 'grey'"> fas fa-map-marked </v-icon>
                                            </v-btn>
                                        </template>
                                        Voir le programme sur une carte
                                    </v-tooltip>
                                </v-item>

                                <v-item :value="'carousel'" v-slot="{ active, toggle }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="toggle" icon v-on="on">
                                                <v-icon :color="active ? 'primary' : 'grey'"> fas fa-th </v-icon>
                                            </v-btn>
                                        </template>
                                        Voir les photos du programme
                                    </v-tooltip>
                                </v-item>
                            </v-item-group>
                        </v-toolbar>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <!-- Description -->
                <v-col cols="12" lg="6" xl="5">
                    <v-card class="axessia-card fill-height" outlined>
                        <v-card-text>
                            <!-- Adresse -->
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="3">
                                                Adresse
                                            </v-col>
                                            <v-col class="black--text">
                                                {{ program.number }} {{ program.address.trim() }}, {{ program.city }} ({{ program.zip }})
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <!-- Fiscalité -->
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="3">
                                                Fiscalité
                                            </v-col>
                                            <v-col class="black--text">
                                                <span v-for="(fiscalite, i) of program.fiscalite" :key="fiscalite.key">
                                                    {{ fiscalite.label }}<template v-if="i !== program.fiscalite.length -1">,</template>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <!-- Typologie -->
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="3">
                                                Typologie
                                            </v-col>
                                            <v-col class="black--text" v-html="getAvailableProductsRange(program, true)">

                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <!-- Espace de vente -->
                            <v-hover v-if="getConfig('programs.display_espace_de_vente', true)" v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="3">
                                                Espace de vente
                                            </v-col>
                                            <v-col class="black--text">
                                                {{ program.bdv.number }} {{ program.bdv.address.trim() }}<template v-if="program.bdv.city">, {{ program.bdv.city }}</template>

                                                <template v-if="program.bdv.zip">
                                                    ({{ program.bdv.zip }})
                                                </template>

                                                <template v-if="program.bdv.email">
                                                    <br>
                                                    {{ program.bdv.email }}
                                                </template>

                                                <template v-if="program.bdv.phone">
                                                    <br>
                                                    Tél : {{ program.bdv.phone }}
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <template v-if="getConfig('programs.display_urls', false)">
                                <!-- URL 3D -->
                                <v-hover v-if="program.translation.urls_3d" v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="3">
                                                    URL 3D
                                                </v-col>

                                                <v-col class="black--text">
                                                    <v-btn :href="program.translation.urls_3d" target="_blank" color="secondary lighten-4" x-small>
                                                        Ouvrir
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>

                                <!-- URL plaquette -->
                                <v-hover v-if="program.translation.url_plaquette" v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="3">
                                                    URL plaquette
                                                </v-col>

                                                <v-col class="black--text">
                                                    <v-btn :href="program.translation.url_plaquette" target="_blank" color="secondary lighten-4" x-small>
                                                        Ouvrir
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>

                                <!-- URL programme -->
                                <v-hover v-if="program.translation.url_directe" v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="3">
                                                    URL programme
                                                </v-col>

                                                <v-col class="black--text">
                                                    <v-btn :href="program.translation.url_directe" target="_blank" color="secondary lighten-4" x-small>
                                                        Ouvrir
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>

                                <!-- Vidéo -->
                                <v-hover v-if="program.translation.url_video" v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="3">
                                                    Vidéo
                                                </v-col>

                                                <v-col class="black--text">
                                                    <v-btn :href="program.translation.url_video" target="_blank" color="secondary lighten-4" x-small>
                                                        Ouvrir
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>

                                <!-- Visite virtuelle -->
                                <v-hover v-if="program.translation.url_visite_virtuelle_prg" v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="3">
                                                    Visite virtuelle
                                                </v-col>

                                                <v-col class="black--text">
                                                    <v-btn :href="program.translation.url_visite_virtuelle_prg" target="_blank" color="secondary lighten-4" x-small>
                                                        Ouvrir
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>

                                <!-- Visite virtuelle lot type -->
                                <v-hover v-if="program.translation.url_visite_virtuelle_prd" v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="3">
                                                    Visite virtuelle lot type
                                                </v-col>

                                                <v-col class="black--text">
                                                    <v-btn :href="program.translation.url_visite_virtuelle_prd" target="_blank" color="secondary lighten-4" x-small>
                                                        Ouvrir
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>
                            </template>

                            <!-- Description -->
                            <v-hover v-slot:default="{ hover }">
                                <v-card class="pa-1" flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="3">
                                                Description
                                            </v-col>

                                            <v-col class="black--text">
                                                <div style="max-height: 224px; overflow-y: auto;">
                                                    <div v-html="program.translation[getConfig('programs.translation_description_code', 'descriptif_court')]">

                                                    </div>
                                                </div>

                                                <DescriptionDialog v-if="program.translation[getConfig('programs.translation_description_code', 'descriptif_court')]" :description="program.translation[getConfig('programs.translation_description_code', 'descriptif_court')]" />
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- Programs slider -->
                <v-col cols="12" lg="6" xl="7">
                    <v-carousel v-show="view === 'carousel'" ref="carousel" class="rounded fill-height" hide-delimiters>
                        <v-carousel-item v-for="photo in program.photos" :key="photo.id">
                            <!-- <v-sheet color="black" style="position: absolute; opacity: 0.9; width: 100%; height: 100%;"> </v-sheet> -->
                            <v-img :src="photo.info.url" height="100%" style="position: absolute; filter : brightness(30%);"> </v-img>
                            <v-img :src="photo.info.url" contain height="100%" style="position: absolute;"> </v-img>

                            <v-row v-if="program.translation[getConfig('programs.translation_promote_code', 'promo_1')]" class="fill-height" align="center" justify="center">
                                <v-col cols="6">
                                    <v-card style="background-color: rgba(255, 255, 255, 0.8)" shaped>
                                        <v-card-text class="text-center">
                                            <h3 class="red--text"> {{ program.title }} </h3>

                                            <div v-html="program.translation[getConfig('programs.translation_promote_code', 'promo_1')]">

                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-carousel-item>

                        <v-carousel-item v-if="program.photos.length === 0" src="https://cdn.shopify.com/s/files/1/1661/5755/products/property-placeholder_grande.jpg?v=1482778691" />
                    </v-carousel>

                    <div v-show="view === 'map'" :style="{ height: mapContainerHeight }">
                        <l-map ref="map" style="z-index: 1;" class="rounded">
                            <l-tile-layer :url="leaflet.url"></l-tile-layer>

                            <l-marker v-if="program.latitude && program.longitude" :lat-lng="[program.latitude, program.longitude]">
                            </l-marker>
                        </l-map>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <!-- Détails -->
                <v-col cols="12" lg="4">
                    <v-card class="axessia-card fill-height" outlined>
                        <v-toolbar class="mb-0 mx-4" dense flat>
                            <v-toolbar-title class="primary--text title">
                                Détails
                            </v-toolbar-title>

                            <v-spacer />

                            <v-chip v-if="getConfig('programs.display_actable', false) && program.saleStatus !== 1" class="mr-2" color="green" dark small label>
                                Actable
                            </v-chip>
                        </v-toolbar>

                        <v-card-text>
                            <!-- Taux de tva -->
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="5">
                                                Taux de TVA réduite
                                            </v-col>

                                            <v-col class="black--text">
                                                <span v-for="(vat, i) of program.reducedVat" :key="i">
                                                    {{ vat }}<template v-if="i !== program.reducedVat.length - 1">,</template>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <!-- Date de livraison -->
                            <v-hover v-if="getConfig('programs.display_delivery_date', true)" v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="5">
                                                Date de livraison
                                            </v-col>

                                            <v-col class="black--text">
                                                <span v-if="program.deliveryDate ">
                                                    {{ program.deliveryDateType | toDeliveryDateType() }} {{ program.deliveryDate | toYearDate() }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                             <!-- Type de résidence -->
                             <v-hover v-if="getParameter('PRG_TYPERESIDENCE') && program.residencetype" v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="5">
                                                Type de résidence
                                            </v-col>

                                            <v-col class="black--text">
                                                <span>
                                                    {{ program.residencetype}}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <!-- Labels -->
                            <v-hover v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="5">
                                                Labels
                                            </v-col>
                                            <v-col class="black--text">
                                                <span v-for="(label, i) of program.labels" :key="i">
                                                    {{ label }}<template v-if="i !== program.labels.length - 1">,</template>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <!-- Taux de rémunération -->
                            <v-hover v-if="program.feeAmount || program.feePercent" v-slot:default="{ hover }">
                                <v-card flat>
                                    <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                        <v-row class="pa-2 text--secondary" no-gutters>
                                            <v-col cols="5">
                                                Taux de rémunération
                                            </v-col>

                                            <v-col class="black--text">
                                                <span>
                                                    <template v-if="program.feeAmount">
                                                        {{ program.feeAmount | round(1) }} €
                                                    </template>
                                                    <template v-if="program.feePercent">
                                                        {{ program.feePercent | round(1) }} %
                                                    </template>
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                                </v-card>
                            </v-hover>

                            <template v-if="getConfig('programs.display_tel_seller', false)">
                                <!-- Contact -->
                                <v-hover v-slot:default="{ hover }">
                                    <v-card flat>
                                        <v-sheet :color="hover ? 'grey lighten-3' : 'white'">
                                            <v-row class="pa-2 text--secondary" no-gutters>
                                                <v-col cols="5">
                                                    Contact
                                                </v-col>

                                                <v-col class="black--text">
                                                    <div v-if="program.translation.tel_vendeur " v-html="program.translation.tel_vendeur">
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-card>
                                </v-hover>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- Documents -->
                <v-col cols="12" lg="4">
                    <v-card class="axessia-card fill-height" outlined>
                        <v-toolbar class="mb-0 mx-4" dense flat>
                            <v-toolbar-title class="primary--text title">
                                Documents
                            </v-toolbar-title>

                            <v-spacer />

                            <DownloadDocumentsButton :program="program" />
                        </v-toolbar>

                        <v-card-text>
                            <div style="max-height: 400px; overflow-y: auto">
                                <div class="px-4">
                                    <template v-if="getConfig('program.display_documents_by_categories', true)">
                                        <DocumentsListByCategories :documentCategories="documentCategories" />
                                    </template>
                                    <template v-else>
                                        <DocumentsList :documents="program.documents" />
                                    </template>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!-- Actualités -->
                <v-col cols="12" lg="4">
                    <v-card class="d-flex flex-column fill-height" style="max-height: 500px" outlined>
                        <template v-if="actualities.length > 0">
                            <v-window v-model="actualitiesPage" class="fill-height">
                                <v-window-item v-for="actuality of actualities" :key="actuality.id" class="fill-height">
                                    <ActualityCard :initActuality="actuality" />
                                </v-window-item>
                            </v-window>

                            <v-card-actions v-if="actualities.length > 1" class="justify-space-between flex-grow-1 align-end">
                                <v-btn icon @click="actualitiesPage = actualitiesPage === 0 ? actualities.length - 1 : actualitiesPage - 1">
                                    <v-icon> fas fa-angle-left </v-icon>
                                </v-btn>

                                <v-item-group v-model="actualitiesPage" class="text-center" mandatory>
                                    <v-item v-for="n in actualities" :key="n.keyid" v-slot="{ active, toggle }">
                                        <v-btn :input-value="active" icon @click="toggle">
                                            <v-icon small> fas fa-circle </v-icon>
                                        </v-btn>
                                    </v-item>
                                </v-item-group>

                                <v-btn icon @click="actualitiesPage = actualitiesPage === actualities.length - 1 ? 0 : actualitiesPage + 1">
                                    <v-icon> fas fa-angle-right </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </template>
                        <template v-else>
                            <v-row class="fill-height" align="center">
                                <v-col class="text-center">
                                    <h3> Aucune actualité </h3>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Tabs -->
            <v-row>
                <v-col>
                    <v-tabs v-model="tab" height="45" color="primary" class="rounded" fixed-tabs>
                        <v-tab to="#products" replace>
                            Lots
                        </v-tab>

                        <v-tab to="#contacts" replace>
                            Contacts
                        </v-tab>

                        <v-tab to="#options" replace>
                            Options
                        </v-tab>

                        <v-tab to="#sales" replace>
                            Réservations
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <!-- Tab items -->
            <v-row>
                <v-col>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="products">
                            <Products :programs="[program.id]" embeded />
                        </v-tab-item>

                        <v-tab-item value="contacts">
                            <Contacts :programs="[program.id]" embeded />
                        </v-tab-item>

                        <v-tab-item value="options">
                            <Options :programs="[program.id]" embeded />
                        </v-tab-item>

                        <v-tab-item value="sales">
                            <Sales :programs="[program.id]" embeded />
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </template>

        <template v-if="missingProgram">
            <v-row align="center" class="fill-height">
                <v-col>
                    <h1 class="text-center grey--text text--darken-2"> Désolé, l'accès à ce programme ne vous est plus offert ou bien n'existe pas. </h1>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import Sales from './Sales.vue';
import Options from './Options.vue';
import Contacts from './Contacts.vue';
import Products from './Products.vue';
import programsMixin from '../mixins/programs';
import copyToClipboard from '../helpers/copyToClipboard.js';
import DocumentsList from '../components/program/DocumentsList.vue';
import ActualityCard from '../components/actualities/ActualityCard.vue';
import DescriptionDialog from '../components/program/DescriptionDialog.vue';
import DownloadDocumentsButton from '../components/program/DownloadDocumentsButton.vue';
import CreateDenonciationDialog from '../components/contacts/CreateDenonciationDialog.vue';
import DocumentsListByCategories from '../components/program/DocumentsListByCategories.vue';

export default {
    name: 'Program',

    mixins: [programsMixin],

    components: {
        Sales,
        Options,
        Contacts,
        Products,
        ActualityCard,
        DocumentsList,
        DescriptionDialog,
        DownloadDocumentsButton,
        CreateDenonciationDialog,
        DocumentsListByCategories
    },

    data: () => ({
        tab: 'products',
        actualitiesPage: 0,
        view: 'carousel',
        leaflet: {
            url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
        },
        mapContainerHeight: '0px',

        program: null,
        actualities: [],

        documentCategories: [],
        missingProgram: false
    }),

    methods: {
        copyToClipboard,

        async fetchProgram() {
            try {
                this.setLoading(true);

                const query = { include: 'photos,documents,dispos' };
                const { program, actualities } = await this.repos.programs.getProgram(this.$route.params.programId, query);
                if (!program) {
                    this.missingProgram = true;
                    return;
                }
                this.program = program;
                this.actualities = actualities;
                this.computeDocumentCategories();
            } catch (err) {
                console.error(err);
            } finally {
                this.setLoading(false);
            }
        },

        computeDocumentCategories() {
            this.documentCategories = [];
            for (const document of this.program.documents) {
                const item = this.documentCategories.find((c) => c.category === (document.category || '[Catégorie sans nom]'));
                if (item) {
                    item.documents.push(document);
                } else {
                    const item = {
                        category: document.category || '[Catégorie sans nom]',
                        documents: [document]
                    };
                    this.documentCategories.push(item);
                }
            }

            this.documentCategories.sort((a, b) => {
                if (a.category > b.category) {
                    return 1;
                } else if (a.category < b.category) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
    },

    watch: {
        view(view) {
            if (view === 'map' && this.program.latitude && this.program.longitude) {
                const rect = this.$refs.carousel.$el.getBoundingClientRect();
                this.mapContainerHeight = `${rect.height}px`;
                this.$refs.map.mapObject.fitBounds(
                    [[this.program.latitude, this.program.longitude]]
                );
                setTimeout(() => {
                    this.$refs.map.mapObject.invalidateSize();
                }, 0);
            }
        }
    },

    created() {
        this.fetchProgram();
        this.$store.dispatch('parameters/fetchParameters', ['PRG_TYPERESIDENCE']);
    }
};
</script>
