<template>
    <div>
        <DocumentAlert v-for="document of documents" :key="document.id" :document="document" />
    </div>
</template>

<script>
import DocumentAlert from './DocumentAlert.vue';

export default {
    name: 'DocumentsList',

    props: {
        documents: { type: Array, required: true }
    },

    components: {
        DocumentAlert
    }
};
</script>