<template>
    <v-menu v-if="displayDocuments || displayPhotos" offset-y>
        <template v-slot:activator="{ on }">
            <v-btn color="primary" small v-on="on">
                <v-icon small left> fas fa-download </v-icon>
                Télécharger
                <v-icon small right> fas fa-caret-down </v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-if="displayDocuments" :href="documentsUrl" target="_blank">
                Documents
            </v-list-item>
            <v-list-item v-if="displayPhotos" :href="mediasUrl" target="_blank">
                Visuels
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'DownloadDocumentsButton',

    props: {
        program: { type: Object, required: true }
    },

    computed: {
        displayDocuments() {
            return this.getConfig('program.allow_documents_download', true) && this.program.documents.length > 0;
        },

        displayPhotos() {
            return this.getConfig('program.allow_photos_download', true) && this.program.photos.length > 0;
        },

        documentsUrl() {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/programs/${this.program.id}/download?type=documents`;
        },

        mediasUrl() {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/programs/${this.program.id}/download?type=photos`;
        }
    }
};
</script>