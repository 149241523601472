<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <v-btn class="mt-2" color="primary" x-small v-on="on">
                Lire plus
            </v-btn>
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Description
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <div v-html="description">

                </div>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DescriptionDialog',

    props: {
        description: { type: String }
    },

    data: () => ({
        isOpen: false
    })
};
</script>